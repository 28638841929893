// import Vue from 'vue'
import { Component, Watch } from "vue-property-decorator";
import baseUserContextPage from "../baseUserContextPage";
import axios, { AxiosPromise } from "axios";
import { orderDetailModel } from "@/models/orderDetailModel";
import { pagedList } from "@/models/pagedList";
import { orderModel } from "@/models/orderModel";
import { CustomerInfoModel } from "@/models/customerModels";

@Component({})
export default class customerPage extends baseUserContextPage {
  get id(): number {
    return this.$route && this.$route.params && this.$route.params.id
      ? Number.parseInt(this.$route.params.id)
      : null;
  }
  get ordersShowDestination(): boolean {
    return false;
    //return this.isUserB2BAdmim || this.isUserRefEmployee || this.isUserEmployee;
  }
  get ordersEnableSearch(): boolean {
    return this.isUserB2BAdmim || this.isUserRefEmployee || this.isUserEmployee;
  }
  item: CustomerInfoModel = new CustomerInfoModel();

  isLoading: boolean = false;

  fetch(): void {
    var instance = this;

    if (instance.id != null) {
      instance.isLoading = true;
      axios
        .get<CustomerInfoModel>(`customer/${instance.id}`)
        .then(res => {
          instance.item = res.data ? res.data : new CustomerInfoModel();
          instance.setCurrentCustomer(instance.item);
          instance.isLoading = false;
        })
        .catch(error => {
          console.error(error);
          instance.isLoading = false;
        });
    }
  }
  mounted() {
    var instance = this;

    instance.fetch();
  }
}
